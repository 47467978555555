import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  mainRed,
  navyBlue,
  screen,
  innerWidth,
  gotham,
  lightNavyBlue,
  lightBlue,
  mainBlue,
  mainWhite,
  gothamBold,
  transHover,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { formatDate } from "../components/helpers";
import ReactPlayer from "react-player";
import {
  IoIosMail,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoFacebook,
} from "react-icons/io";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import Arrow from "../images/svg/right-arrow.svg";
import IframePopup from "../components/iframe-popup";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${gothamBold};
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: 20px;
    max-width: 280px;
    line-height: 1.35;
    @media ${screen.small} {
      font-size: 2.8rem;
      margin-top: 45px;
      max-width: 1040px;
    }
  }

  .published-date {
    color: ${navyBlue};
    font-family: ${gotham};
    font-size: 1rem;
    margin-top: 10px;
    @media ${screen.small} {
      font-size: 1.1rem;
    }
  }

  .content {
    max-width: 800px;
    margin-top: 25px;
    @media ${screen.small} {
      margin-top: 45px;
    }
    @media ${screen.large} {
      max-width: 1360px;
    }

    &__full-text {
      color: ${navyBlue};
      margin: 25px 0;
      @media ${screen.small} {
        margin: 50px 0;
      }

      p {
        font-family: ${gotham};
        font-size: 1rem;
        margin-bottom: 16px;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-family: ${gothamBold};
        color: ${navyBlue};
        &:hover {
          text-decoration: underline;
        }
      }

      strong {
        font-family: ${gothamBold};
      }

      h1 {
        font-size: 2.2rem;
        font-family: ${gothamBold};
        margin: 10px 0;
      }
      h2 {
        font-size: 2rem;
        font-family: ${gothamBold};
        margin: 10px 0;
      }
      h3 {
        font-size: 1.8rem;
        font-family: ${gothamBold};
        margin: 10px 0;
      }
      h4 {
        font-size: 1.6rem;
        font-family: ${gothamBold};
        margin: 10px 0;
      }
      h5 {
        font-size: 1.4rem;
        font-family: ${gothamBold};
        margin: 10px 0;
      }
      h6 {
        font-size: 1.2rem;
        font-family: ${gothamBold};
        margin: 10px 0;
      }
    }

    &__blockquote {
      color: ${navyBlue};
      max-width: 920px;
      margin: 25px auto;
      padding-left: 25px;
      position: relative;
      @media ${screen.small} {
        margin: 50px auto;
        padding-left: 45px;
      }

      p {
        font-size: 1rem;
        font-style: italic;
        margin-bottom: 16px;
        font-family: ${gotham};
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: ${navyBlue};
        font-size: 1.1rem;
        font-family: ${gothamBold};
        &:hover {
          text-decoration: underline;
        }
      }

      &::before {
        background-image: linear-gradient(
          45deg,
          ${navyBlue},
          ${lightNavyBlue},
          ${mainBlue},
          ${lightBlue}
        );
        content: "";
        position: absolute;
        left: 0;
        width: 5px;
        height: 100%;
        display: block;
        @media ${screen.small} {
          width: 7px;
        }
      }
    }

    &__left-aligned-img {
      display: flex;
      flex-direction: column;
      margin: 25px 0;
      @media ${screen.small} {
        margin: 50px 0;
        flex-direction: row;
      }

      ul,
      ol {
        margin: 15px 0 30px 0;
        padding-left: 30px;
        @media ${screen.small} {
          padding-left: 45px;
        }

        li {
          color: ${navyBlue};
          line-height: 1.5;
          list-style: inherit;
          font-family: ${gotham};
          margin-bottom: 16px;
        }
      }

      .img {
        width: 100%;
        margin-bottom: 15px;
        @media ${screen.small} {
          width: 50%;
          padding-right: 50px;
          margin-bottom: 0;
        }

        .caption {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 0.8rem;
          margin-top: 8px;
          display: block;
          @media ${screen.small} {
            font-size: 1rem;
          }
        }
      }

      .description {
        width: 100%;
        color: ${navyBlue};
        @media ${screen.small} {
          width: 50%;
        }

        p {
          font-family: ${gotham};
          font-size: 1rem;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          color: ${navyBlue};
          font-family: ${gothamBold};
          &:hover {
            text-decoration: underline;
          }
        }

        h1 {
          font-size: 2rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2.2rem;
          }
        }
        h2 {
          font-size: 1.8rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2rem;
          }
        }
        h3 {
          font-size: 1.6rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.8rem;
          }
        }
        h4 {
          font-size: 1.5rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.6rem;
          }
        }
        h5 {
          font-size: 1.3rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.4rem;
          }
        }
        h6 {
          font-size: 1.1rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.2rem;
          }
        }
      }
    }

    &__right-aligned-img {
      display: flex;
      flex-direction: column;
      margin: 25px 0;
      @media ${screen.small} {
        margin: 50px 0;
        flex-direction: row;
      }

      ul,
      ol {
        margin: 15px 0 30px 0;
        padding-left: 30px;
        @media ${screen.small} {
          padding-left: 45px;
        }

        li {
          color: ${navyBlue};
          line-height: 1.5;
          list-style: inherit;
          font-family: ${gotham};
          margin-bottom: 16px;
        }
      }

      .img {
        width: 100%;
        margin-bottom: 15px;
        @media ${screen.small} {
          width: 50%;
          padding-left: 50px;
          margin-bottom: 0;
        }

        .caption {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 0.8rem;
          margin-top: 8px;
          display: block;
          @media ${screen.small} {
            font-size: 1rem;
          }
        }
      }

      .description {
        width: 100%;
        color: ${navyBlue};
        @media ${screen.small} {
          width: 50%;
        }

        p {
          font-family: ${gotham};
          font-size: 1rem;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          color: ${navyBlue};
          font-family: ${gothamBold};
          &:hover {
            text-decoration: underline;
          }
        }

        h1 {
          font-size: 2rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2.2rem;
          }
        }
        h2 {
          font-size: 1.8rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2rem;
          }
        }
        h3 {
          font-size: 1.6rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.8rem;
          }
        }
        h4 {
          font-size: 1.5rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.6rem;
          }
        }
        h5 {
          font-size: 1.3rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.4rem;
          }
        }
        h6 {
          font-size: 1.1rem;
          font-family: ${gothamBold};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.2rem;
          }
        }
      }
    }

    &__hyperlink {
      margin: 25px 0 50px 0;
      @media ${screen.small} {
        margin: 50px 0;
      }

      a {
        color: ${mainWhite};
        background: ${navyBlue};
        display: table;
        font-family: ${gothamBold};
        font-size: 1.1rem;
        padding: 13px 20px;
        transition: ${transHover};
        &:hover {
          background: ${mainRed};
        }
      }
    }

    &__full-video {
      position: relative;
      padding-top: 56.25%;
      margin: 25px 0;
      @media ${screen.small} {
        margin: 50px 0;
      }

      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .social-share {
    display: flex;
    align-items: center;
    max-width: 340px;
    margin-top: 80px;
    @media ${screen.small} {
      margin-top: 120px;
      justify-content: space-between;
    }

    p {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1.1rem;
    }

    &__icon {
      cursor: pointer;
      transition: ${transHover};
      outline: none;

      svg {
        width: 32px;
        height: 32px;
        margin: 0 14px;
        color: ${navyBlue};
        transition: ${transHover};
        @media ${screen.small} {
          width: 35px;
          height: 35px;
        }

        &:hover {
          color: ${mainRed};
        }
      }
    }
  }

  .nav-control {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;

    &__prev {
      position: relative;

      a {
        color: ${navyBlue};
        font-size: 0.8rem;
        font-family: ${gotham};
        display: block;
        margin-left: 20px;
        transition: ${transHover};
        @media ${screen.small} {
          font-size: 1.1rem;
        }
      }

      svg {
        max-width: 9px;
        transform: scale(-1) translateY(60%);
        position: absolute;
        top: 50%;
        left: 4px;
        @media ${screen.small} {
          max-width: 12px;
          left: 0;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      &:hover a {
        color: ${mainRed};
      }
      &:hover .body {
        fill: ${mainRed};
      }
    }

    &__next {
      position: relative;

      a {
        color: ${navyBlue};
        font-size: 0.8rem;
        font-family: ${gotham};
        display: block;
        margin-right: 20px;
        transition: ${transHover};
        @media ${screen.small} {
          font-size: 1.1rem;
        }
      }
      svg {
        max-width: 9px;
        position: absolute;
        transform: translateY(-60%);
        top: 50%;
        right: 4px;
        @media ${screen.small} {
          max-width: 12px;
          right: 0;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      &:hover a {
        color: ${mainRed};
      }
      &:hover .body {
        fill: ${mainRed};
      }
    }

    &__all {
      a {
        color: ${navyBlue};
        font-size: 0.8rem;
        font-family: ${gotham};
        display: block;
        transition: ${transHover};
        @media ${screen.small} {
          font-size: 1.1rem;
        }
      }

      &:hover a {
        color: ${mainRed};
      }
    }
  }
`;

const BlogPage = ({ data, pageContext }) => {
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : data.content.data.title.text;
  const metaDescription =
    data.content.data.meta_description && data.content.data.meta_description;
  const imgBanner =
    data.content.data.blog_featured_image.gatsbyImageData &&
    data.content.data.blog_featured_image.gatsbyImageData;
  const imgURL = data.content.data.blog_featured_image.url;
  const altBanner = data.content.data.blog_featured_image.alt || "Banner";
  const blogTitle = data.content.data.title.text;
  const publishedDate = data.content.data.edit_published_date
    ? formatDate(data.content.data.edit_published_date, "Do MMMM YYYY")
    : formatDate(data.content.first_publication_date, "Do MMMM YYYY");
  const nextBlog = pageContext.next ? pageContext.next.slug : null;
  const prevBlog = pageContext.prev ? pageContext.prev.slug : null;

  const isPopup = data.content.data.pop_up;

  const shareURL = typeof window !== "undefined" && window.location.href;

  useEffect(() => {
    setTimeout(() => {
      setIsIframeOpen(true);
    }, 20000);
  }, []);

  // array of JSX with the content from Prismic slices
  const renderContent = data.content.data.body.map((content) => {
    if (content.slice_type === `full_text`) {
      return (
        <div
          className="content__full-text"
          dangerouslySetInnerHTML={{ __html: content.primary.description.html }}
          key={content.id}
        />
      );
    }

    if (content.slice_type === `blockquote`) {
      return (
        <div
          className="content__blockquote"
          dangerouslySetInnerHTML={{ __html: content.primary.description.html }}
          key={content.id}
        />
      );
    }

    if (content.slice_type === `left_image_w__text`) {
      return (
        <div className="content__left-aligned-img" key={content.id}>
          <div
            className="img"
            style={{ maxWidth: content.primary.image.dimensions.width * 1.27 }}
          >
            {content.primary.image.gatsbyImageData && (
              <GatsbyImage
                image={content.primary.image.gatsbyImageData}
                alt={content.primary.image.alt || "Featured"}
              />
            )}
            <span className="caption">
              {content.primary.caption && content.primary.caption}
            </span>
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: content.primary.description.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === `right_image_w__text`) {
      return (
        <div className="content__right-aligned-img" key={content.id}>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: content.primary.description.html,
            }}
          />
          <div
            className="img"
            style={{ maxWidth: content.primary.image.dimensions.width * 1.27 }}
          >
            {content.primary.image.gatsbyImageData && (
              <GatsbyImage
                image={content.primary.image.gatsbyImageData}
                alt={content.primary.image.alt || "Featured"}
              />
            )}

            <span className="caption">
              {content.primary.caption && content.primary.caption}
            </span>
          </div>
        </div>
      );
    }

    if (content.slice_type === `hyperlink`) {
      return (
        <div className="content__hyperlink" key={content.id}>
          <a
            href={content.primary.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.primary.label}{" "}
          </a>
        </div>
      );
    }

    if (content.slice_type === `full_width_video`) {
      return (
        <div className="content__full-video" key={content.id}>
          <ReactPlayer
            className="video"
            url={content.primary.url}
            preload="true"
          />
        </div>
      );
    }
  });

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} image={imgURL} />
      <Wrapper>
        <div>
          {imgBanner && <GatsbyImage image={imgBanner} alt={altBanner} />}
        </div>
        <h1 className="heading">{blogTitle}</h1>
        <p className="published-date">
          Published by TEN ALPHAS | {publishedDate}
        </p>
        <div className="content">{renderContent}</div>
        <div className="social-share">
          <p>Share: </p>
          <EmailShareButton
            className="social-share__icon"
            url={shareURL}
            children={<IoIosMail />}
          />
          <TwitterShareButton
            className="social-share__icon"
            url={shareURL}
            children={<IoLogoTwitter />}
          />
          <LinkedinShareButton
            className="social-share__icon"
            url={shareURL}
            children={<IoLogoLinkedin />}
          />
          <FacebookShareButton
            className="social-share__icon"
            url={shareURL}
            children={<IoLogoFacebook />}
          />
        </div>
        <div className="nav-control">
          <div
            className="nav-control__prev"
            style={{ visibility: nextBlog ? `visible` : `hidden` }}
          >
            <Arrow />
            <Link to={`/news/${nextBlog}`}>Prev Article</Link>
          </div>
          <div className="nav-control__all">
            <Link to="/news">View All</Link>
          </div>
          <div
            className="nav-control__next"
            style={{ visibility: prevBlog ? `visible` : `hidden` }}
          >
            <Link to={`/news/${prevBlog}`}>Next Article</Link>
            <Arrow />
          </div>
        </div>

        {isPopup && isIframeOpen && (
          <IframePopup setIsIframeOpen={setIsIframeOpen} />
        )}
      </Wrapper>
    </Layout>
  );
};

export default BlogPage;

export const data = graphql`
  query($id: String!) {
    content: prismicBlog(id: { eq: $id }) {
      id
      first_publication_date
      data {
        title {
          text
        }
        pop_up
        edit_published_date
        title_tag
        meta_description
        featured_description
        blog_featured_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        body {
          __typename
          ... on PrismicBlogDataBodyBlockquote {
            id
            slice_type
            primary {
              description {
                html
              }
            }
          }
          ... on PrismicBlogDataBodyFullText {
            id
            slice_type
            primary {
              description {
                html
              }
            }
          }
          ... on PrismicBlogDataBodyFullWidthVideo {
            id
            slice_type
            primary {
              url
            }
          }
          ... on PrismicBlogDataBodyHyperlink {
            id
            slice_type
            primary {
              label
              url
            }
          }
          ... on PrismicBlogDataBodyLeftImageWText {
            id
            slice_type
            primary {
              caption
              image {
                alt
                dimensions {
                  width
                  height
                }
                gatsbyImageData(layout: FULL_WIDTH)
              }
              description {
                html
              }
            }
          }
          ... on PrismicBlogDataBodyRightImageWText {
            id
            slice_type
            primary {
              caption
              image {
                alt
                dimensions {
                  width
                  height
                }
                gatsbyImageData(layout: FULL_WIDTH)
              }
              description {
                html
              }
            }
          }
        }
      }
    }
  }
`;
