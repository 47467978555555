import React from "react";
import { screen, lightNavyBlue } from "../common/variables";
import { fadeIn } from "../common/animations";
import styled from "styled-components";
import Iframe from "react-iframe";
import Close from "../../images/svg/close-icon.svg";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  animation: ${fadeIn} 0.2s ease-out;
  width: 100%;
  padding-top: 56.25%;

  #iframe-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    margin: 15px;

    @media ${screen.small} {
      width: calc(100% - 80px);
      height: calc(100% - 80px);
      margin: 40px;
    }

    @media ${screen.medium} {
      width: calc(100% - 140px);
      height: calc(100% - 140px);
      margin: 70px;
    }

    #iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: ${lightNavyBlue} 2px solid;
      @media ${screen.small} {
        border: ${lightNavyBlue} 4px solid;
      }
    }

    svg {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
      z-index: 9;
      @media ${screen.small} {
        top: 38px;
        right: 38px;
        width: 38px;
        height: 38px;
      }
    }

    .mcnPageBannerContainer {
    }
  }
`;

const IframePopup = ({ setIsIframeOpen }) => {
  return (
    <Wrapper>
      <div id="iframe-wrapper">
        <Close
          onClick={() => {
            setIsIframeOpen(false);
          }}
        />
        <Iframe
          url="https://mc.tenalphas.com.au/cos-top-ten-tips-and-taoc-2-sept-2020"
          id="iframe"
          display="block"
          position="relative"
        />
      </div>
    </Wrapper>
  );
};

export default IframePopup;
